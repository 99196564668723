import { ISemesterDetails } from "../models/semesters/response";
import { ISpecializationQuery } from "../models/specialization/query";
import { ICreateSpecialization, IUpdateSpecialization } from "../models/specialization/request";
import { ISpecialization } from "../models/specialization/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class SpecializationService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllSpecializations = (query: ISpecializationQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<ISpecialization>>({
      path: `/specializations`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getSpecialization = (id: number, params?: RequestParams) =>
    this.http.request<ISemesterDetails>({
      path: `/specializations/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createSpecialization = (data: ICreateSpecialization, params?: RequestParams) =>
    this.http.request<ISpecialization>({
      path: "/specializations",
      method: "POST",
      body: data,
      secure: true,
      ...params,
    });

  updateSpecialization = (
    id: number,
    data: IUpdateSpecialization,
    params?: RequestParams
  ) =>
    this.http.request<ISpecialization>({
      path: `/specializations/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deleteSpecialization = (id: number, params?: RequestParams) =>
    this.http.request<ISpecialization>({
      path: `/specializations/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default SpecializationService;
