import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { setToken } from "../services/authManager";
// import { User } from "src/types";

export interface UserState {
  isAuthenticated?: boolean;
  accessToken?: string | null;
  user?: any | null;
}

const initialState: UserState = {
  isAuthenticated: !!Cookies.get("token"),
  accessToken: Cookies.get("token") ?? null,
  user: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state, action: any) => {
      state.accessToken = null;
      state.isAuthenticated = false;
      setToken(null);
      // Cookies.remove("token");
    },
    setTokens: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
      state.isAuthenticated = true;
    },
    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
      state.isAuthenticated = true;
      state.accessToken = action.payload.token;
    },
  },
});

export const { setTokens, setUser } = userSlice.actions;

export default userSlice.reducer;
