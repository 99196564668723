import AcademicLevelService from "../api/AcademicLevels";
import { Auth } from "../api/Auth";
import BooksService from "../api/Books";
import BuildingsService from "../api/buildings";
import ClassesService from "../api/Classes";
import ClassroomService from "../api/Classroom";
import ClassroomBooksService from "../api/ClassroomBooks";
import ClassroomSemestersService from "../api/ClassroomSemester";
import ClassroomSubjectsService from "../api/ClassroomSubjects";
import EvaluationComponentService from "../api/Evaluation-components";
import GradesService from "../api/grades";
import HallsService from "../api/Hall";
import HallTypesService from "../api/HallTypes";
import PeriodAcademicService from "../api/periodAcademic";
import QualificationService from "../api/Qualifications";
import SchoolesService from "../api/Schooles";
import SemesterService from "../api/Semesters";
import SkillsService from "../api/Skills";
import SpecializationService from "../api/Specializations";
import SubjectsService from "../api/Subjects";
import SupervisorsService from "../api/Supervisors";
import WorkflowService from "../api/Workflow";
import { httpclient } from "./http-client";

export default class EndPoints {
  //mangment
  public static auth = new Auth(httpclient);
  public static qualifications = new QualificationService(httpclient);
  public static periodAcademics = new PeriodAcademicService(httpclient);
  public static semesters = new SemesterService(httpclient);
  public static specializations = new SpecializationService(httpclient);
  public static evaluationComponents = new EvaluationComponentService(httpclient);
  public static schooles = new SchoolesService(httpclient);
  public static academicLevels = new AcademicLevelService(httpclient);
  public static subjects = new SubjectsService(httpclient);
  public static skills = new SkillsService(httpclient);
  public static grades = new GradesService(httpclient);
  //schole building 
  public static hallTypes = new HallTypesService(httpclient);
  public static halls = new HallsService(httpclient);
  public static buildings = new BuildingsService(httpclient);
  public static books = new BooksService(httpclient);
  // classroom 
  public static classroom = new ClassroomService(httpclient);
  public static classroomSemesters= new ClassroomSemestersService(httpclient);
  public static supervisor= new SupervisorsService(httpclient);
  public static classes= new ClassesService(httpclient);
  public static classroomSubjects= new ClassroomSubjectsService(httpclient);
  public static classroomBooks= new ClassroomBooksService(httpclient);
  //workflow
  public static workflow= new WorkflowService(httpclient);
}
