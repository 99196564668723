import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { ConfigProvider, ThemeConfig, theme } from "antd";
import { getLanguage, getTheme } from "../../../state";
import {
  EVENT_FORBIDDEN,
  EVENT_SERVER_ERROR,
  EVENT_SUCCESS,
  EVENT_UNAUTHORIZED,
} from "../../../utils/event-manager";
import eventManager from "../../../utils/event-manager";
import {
  errorNotification,
  successNotification,
} from "../../../utils/helpers/notification";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { setToken } from "../../../services/authManager";
import { useMediaQuery } from "react-responsive";
import ScreenSize from "../../../utils/ui/screen-size";

const AntdConfigProvider = ({ children }: { children: any }) => {
  const effectCalled = useRef(false);
  const theme = useSelector(getTheme);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isLaptopOrDesktop = useMediaQuery({
    minWidth: 992,
  });
  const isMobileOrTablet = useMediaQuery({
    maxWidth: 992,
  });
  useEffect(() => {
    let screenSize: ScreenSize;

    if (isLaptopOrDesktop) screenSize = "laptopOrDesktop";
    else screenSize = "mobileOrTablet";
    // TODO add to redux app slice
    // dispatch({
    //   type: "SET_SCREEN_SIZE",
    //   payload: { screenSize },
    // });
  }, [isLaptopOrDesktop, isMobileOrTablet]);

  useEffect(() => {
    if (!effectCalled.current) {
      effectCalled.current = true;
      eventManager.on(EVENT_SUCCESS, (message?: string) => {
        successNotification(message ?? t("operationDoneSuccessfully"));
      });
      eventManager.on(EVENT_SERVER_ERROR, (message) => {
        errorNotification(message);
      });
      eventManager.on(EVENT_UNAUTHORIZED, () => {
        setToken(null);
        navigate("/auth/login", { replace: true });
      });
      eventManager.on(EVENT_FORBIDDEN, () => {
        navigate("/403", { replace: true });
      });
    }
  }, []);

  const direction = "ar" === useSelector(getLanguage) ? "rtl" : "ltr";
  const costumeTheme: ThemeConfig = {
    components: {
      Grid: { colorBgBase: "red" },

      Layout: {
        // colorBgBody: "#b3b6b3",
      },
      DatePicker: {
        colorLink: "#2b508f",
        colorLinkHover: "#2b508f",
      },
    },
    token: {
      colorPrimary: "#2b508f",

      fontFamily: "Cairo",
      colorBgLayout: "#f1f2f6",
    },
  };

  const darkTheme: ThemeConfig = {
    components: {
      Grid: { colorBgBase: "red" },
      Table: { colorBgContainer: "#000c17", colorBgLayout: "#000c17" },
      Layout: {
        // colorBgBody: "#b3b6b3",
      },
      DatePicker: {
        colorLink: "#2b508f",
        colorLinkHover: "#0e171c",
      },
      Input: {
        colorBgContainer: "#000c17",
      },
      InputNumber: {
        colorBgContainer: "#000c17",
      },
      Select: {
        colorBgContainer: "#000c17",
        colorTextLabel: "white",
        colorTextPlaceholder: "white",
      },
      Card: {
        colorBgContainer: "#000c17",
      },
    },
    token: {
      colorText: "white",
      colorPrimary: "#2b508f",
      fontFamily: "Cairo",
      colorBgLayout: "#0e171c",
      colorBgContainer: "#0e171c",
      colorBgElevated: "#0e171c",
      colorTextDescription: "white",
      colorTextLabel: "white",
      colorTextPlaceholder: "white",
    },
  };

  return (
    <ConfigProvider
      theme={theme == "light" ? costumeTheme : darkTheme}
      direction={direction}
    >
      {children}
    </ConfigProvider>
  );
};
export default AntdConfigProvider;
